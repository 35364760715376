import React from "react";
import Layout from "../components/Global/Layout/Layout"
import HeroSection from "../components/PartnershipsNew/HeroSection/HeroSection";
import HowPartnersHelp from "../components/PartnershipsNew/HowPartnersHelp/HowPartnersHelp"
import PartnerNotASupplier from "../components/PartnershipsNew/PartnerNotASupplier/PartnerNotASupplier";
import OurPartnerPackage from "../components/PartnershipsNew/OurPartnerPackage/OurPartnerPackage";
import ReadyToBecomeAPartner from "../components/PartnershipsNew/ReadyToBecomeAPartner/ReadyToBecomeAPartner"
import SEO from "../components/Global/SEO/seo"

const PartnershipPage = () => (
    <Layout
      headerAbsolute
      headerTransparent
      headerMobile={'bgWhite'}
      whiteToggler
      logoDark={true}
      helpSectionColor={'white-bg'}
    >        
        <SEO page="partnerships" />
        <HeroSection/>
        <HowPartnersHelp/>
        <PartnerNotASupplier/>
        <OurPartnerPackage/>
        <ReadyToBecomeAPartner/>
    </Layout>
);

export default PartnershipPage

/* 
import React from "react"
import Layout from "../components/Global/Layout/Layout"
import HeroSection from "../components/PartnershipsPage/HeroSection/HeroSection"
import PartnerShipIntro from "../components/PartnershipsPage/PartnerShipIntro/PartnerShipIntro"
import PartnerShipPrograms from "../components/PartnershipsPage/PartnerShipPrograms/PartnerShipPrograms"
import PartnerBrands from "../components/PartnershipsPage/PartnerBrands/PartnerBrands"
import SEO from "../components/Global/SEO/seo"

const PartnershipsPage = () => {
  return (
    <Layout>

<SEO page="partnerships"/>
      <HeroSection />
      <PartnerShipIntro />
      <PartnerShipPrograms />
      <PartnerBrands />
    </Layout>
  )
}

export default PartnershipsPage
 */